@import "./reset";
@import "./fonts";
@import "./variables";

:root {
	--base-font-size: 16px;
	--padding: 1rem calc(2vw + 0.25rem);
	--default-text: rgba(0, 0, 0, 0.8);
	--default-text-size: calc(1.375rem);
	--default-font-weight: 400;
	--bold-font-weight: 500;

	--red-orange: #be4949;
	--background-color: white;
	--emph-background: var(--red-orange);
	--emph-text: white;

	--breakpoint-medium: 32rem;	
	--background-image: url("../Assets/background-light.gif");

	@media (prefers-color-scheme: dark) {
		--default-font-weight: 300;
		--default-text: white;
		--background-color: #191b1b;
		--emph-background: var(--red-orange);
		--background-image: url("../Assets/background-dark.gif");
	}
}

html {
	height: 100%;
}

html,
body {
	font-family: "Rubik", sans-serif;
	font-size: var(--base-font-size);
	font-weight: var(--default-font-weight);
	color: var(--default-text);
	min-height: 100vh;
	max-width: 80rem;
	margin: 0 auto;
	--webkit-font-smoothing: subpixel-antialiased;
	background-color: var(--background-color);

	@media screen and (max-width: $breakpoint-medium) and (prefers-reduced-motion: no-preference) {
		background-image: var(--background-image);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
}

a {
	text-decoration: none;

	&:visited,
	&:link,
	&:hover,
	&:active {
		color: var(--default-text);
	}
}

h2 {
	font-size: clamp(1.5rem, 2vw, 2rem);
	color: var(--default-text);
	font-weight: var(--bold-font-weight);
}

p {
	font-size: 1rem;
}

#root {
	display: grid;
	grid-gap: var(--padding);
	padding: var(--padding);
	min-height: 100vh;
	grid-template-rows: auto 1fr auto;
	align-items: start;
}

#header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: var(--padding);

	h2 {
		font-size: 3rem;
		font-weight: 600;
		white-space: nowrap;

		&::after {
			content: "MW";
		}

		@media (min-width: $breakpoint-medium) {
			&::after {
				content: "Michael Wiedinmyer";
			}
		}
	}

	nav ul li {
		display: inline;
		text-transform: uppercase;
		font-weight: var(--bold-font-weight);
		margin-right: calc(2vw + 0.5rem);

		&:last-child {
			margin-right: 0;
		}

		a {
			font-size: 1.1rem;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

#content {
	display: grid;
	grid-gap: var(--padding);
	padding: var(--padding);
	line-height: 1.7;
	width: 100%;
	margin: 0 auto;
}

#intro {
	grid-column: 1 / -1;
	display: grid;
	grid-template-columns: 1fr;
	justify-items: center;
	align-items: center;
	grid-gap: var(--padding);
	margin: 0 auto;

	@media (min-width: $breakpoint-medium) {
		grid-template-columns: 2fr 5fr;
	}

	#hero {
		img {
			border-radius: 50%;
			overflow: hidden;
		}
	}

	h1 {
		line-height: 150%;
		font-size: clamp(1.5rem, 5vw, 3rem);
		font-weight: var(--bold-font-weight);

		.intro-emph {
			position: relative;
			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;
			place-content: center;
			margin: 0 0.5ch;

			.intro-emph-text {
				min-width: 75%;
				text-align: center;
				position: absolute;
				-webkit-transform: rotate(-5deg);
				-ms-transform: rotate(-5deg);
				transform: rotate(-5deg);
				-webkit-transition: 0.5s all ease-in-out;
				-o-transition: 0.5s all ease-in-out;
				transition: 0.5s all ease-in-out;
				background-color: var(--emph-background);
				color: var(--emph-text);
				border-radius: 4px;
				padding: 0 0.3rem;

				&.is-changing {
					opacity: 0;
					-webkit-transform: translateY(-20px);
					-ms-transform: translateY(-20px);
					transform: translateY(-20px);
					rotate: (-5deg);
					-webkit-transition: 0.5s all ease-in-out;
					-o-transition: 0.5s all ease-in-out;
					transition: 0.5s all ease-in-out;
				}
			}

			.intro-emph-bounds {
				visibility: hidden;
				padding: 0 0.3rem;
			}
		}
	}
}

#blog-feed {
	display: grid;
	grid-gap: var(--padding);
	margin-top: 1rem;
	padding: 0;

	.title {
		font-size: clamp(1.5rem, 2vw, 2rem);
		font-weight: var(--bold-font-weight);
	}

	.blog-feed-article {
		border-bottom: 1px solid var(--default-text);
		padding-bottom: 1.5em;
		align-items: center;

		@media (max-width: $breakpoint-medium) {
			grid-template-columns: 1fr;
		}

		.blog-feed-link {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 0.5em;
		}

		&:hover {
			.blog-feed-title-row > * {
				color: var(--red-orange);
				border-radius: 4px;
				transition: 0.5s color ease-in-out;
			}

			.blog-feed-read-more .blog-feed-read-more-arrow {
				opacity: 1;
			}
		}

		.blog-feed-title-row {
			font-weight: var(--bold-font-weight);
			font-size: 18px;
			font-size: calc(1.375rem);

			& > * {
				transition: 0.5s color ease-in-out;
			}
		}

		p {
			opacity: 0.9;
		}

		.blog-feed-date {
			font-size: 14px;
			font-weight: 400;
		}

		.blog-feed-tags {
			display: flex;

			.blog-feed-tag {
				height: min-content;
				padding: 0.15ch 0.75ch;
				font-size: 12px;
				background-color: var(--default-text);
				color: var(--background-color);
				font-weight: var(--bold-font-weight);
				border-radius: 4px;
				margin-left: 1ch;
				text-transform: uppercase;

				@media (max-width: $breakpoint-medium) {
					margin-left: 0;
					margin-right: 1ch;
					font-size: 14px;
				}
			}
		}

		.blog-feed-read-more {
			font-size: 14px;
			font-weight: 400;
			display: flex;
			align-items: center;

			.blog-feed-read-more-arrow {
				margin-left: 1px;
				opacity: 0;
				transition: 0.5s opacity ease-in-out;
				display: inline;
				height: 24px;
				width: auto;
				transform: translateY(-1px);
			}
		}
	}
}

footer {
	padding: var(--padding);
	margin-top: 14px;
	font-size: 14px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	ul {
		display: flex;

		li {
			margin-left: 1ch;
		}
	}
}
