@import "./_variables";

.blog-article {
	display: grid;
	justify-items: start;
	align-items: center;
	gap: 2em;
	font-size: 14px;

	@media screen and (min-width: $breakpoint-medium) {
		min-width: 50em;
		margin: 0 auto;
	}

	.blog-header {
		padding: 28px 0 16px 0;

		@media screen and (min-width: $breakpoint-medium) {
			justify-self: center;
			text-align: center;
			h3 {
				width: auto !important;
			}
		}

		h3 {
			font-size: 32px;
			line-height: 32px;
			font-weight: 600;
			white-space: nowrap;
			margin-bottom: 6px;
			width: max-content;
		}

		.blog-date {
			font-size: 14px;
			font-weight: 400;
		}
	}

	.blog-tags {
		display: flex;
		height: fit-content;
		justify-content: center;

		.blog-tag {
			display: inline-block;
			font-size: 13px;
			padding: 3px 12px;
			color: white;
			background-color: var(--default-text);
			margin-right: 8px;
			border-radius: 4px;
		}
	}

	.blog-content {
		width: 100%;
		font-size: clamp(14px, calc(1.1875rem), 16px);

		h1 {
			font-size: calc(1.375rem);
		}

		p {
			padding: 1ch 0;

			&:first-of-type {
				padding-top: 0;
			}
		}
	}

	.blog-back-button {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		background: none;
		color: var(--default-text);
		padding: 0;
		margin: 0;
		border: none;
		cursor: pointer;

		.blog-back-button-arrow {
			height: 24px;
			width: auto;
		}
	}
}
